import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { WarmTransferState } from '../../../app.interface';
import {
  CALL_TRANSFER_STATUS,
  LiveContactEventStatus,
  SUPPORTED_STATES,
  WarmTransferEvent,
  WarmTransferRecord,
  WTE_HISTORY_STATUSES,
} from 'common.interfaces';
import {
  getAdvocatesWithInternalWtSkill,
  getIncomingWarmTransferInLicensedStatesAndSkill,
  getOnlineWtAdvocates,
  getSortedAvailableAdvocatesPerStates,
  getSortedHistory,
  getTransferredWTCount,
  getUpdatedIncomingWtsAndHistoryAfterWtEvent,
  isIncomingOlderThan,
  isCallMatchingWte,
  getNonExpiredIncomingTransfers,
  getAvailableWtAdvocates,
} from './warm-transfer.store.utils';
import { computed, inject } from '@angular/core';
import { UserStore } from '../user/user.store';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { EMPTY, catchError, filter, interval, map, pipe, switchMap, tap } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { NotificationService } from '@origin8-web/core-ui/notification';
import { WarmTransferService } from '../../../service-gateways/warm-transfer/warm-transfer.service-gateway';
import { tapResponse } from '@ngrx/operators';
import { LiveStatusStore } from '../live-status/live-status.store';
import { SnackMessageService } from '@origin8-web/core-ui/snack';

const FIVE_SECONDS = 5000; /* In ms */
const ONE_SECOND_AND_HALF = 1500; /* In ms */

const initialState: WarmTransferState = {
  active: false,
  muted: true,
  notifiedWte: [],
  history: [],
  incoming: [],
  loadingAvailableAdvocates: true,
  loadingHistory: true,
  displayHistoryPanel: true,
};

export const WarmTransferStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store, liveStatusStore = inject(LiveStatusStore)) => {
    return {
      workingWtAdvocates: computed(() =>
        getOnlineWtAdvocates(liveStatusStore.representativesProfiles(), liveStatusStore.representativeStatuses()),
      ),
      wtAdvocatesInAvailableStatus: computed(() =>
        getAvailableWtAdvocates(liveStatusStore.representativesProfiles(), liveStatusStore.representativeStatuses()),
      ),
      displayedHistory: computed(() =>
        getSortedHistory(store.history().filter((r) => WTE_HISTORY_STATUSES.some((s) => s === r.callTransferStatus))),
      ),
    };
  }),
  withComputed((store, userStore = inject(UserStore)) => {
    return {
      incomingWarmTransferAvailableForAdvocate: computed(() => {
        return getIncomingWarmTransferInLicensedStatesAndSkill(
          userStore.wtLicensedStates(),
          userStore.wtSkills(),
          store.incoming(),
          store.wtAdvocatesInAvailableStatus(),
        );
      }),
      currentUserWorking: computed(() => {
        return store.workingWtAdvocates().some((a) => a.email === userStore.userInfo()?.email);
      }),
      warmTransferHistoryCount: computed(() => store.displayedHistory().length),
      warmTransferTransferredCount: computed(() => getTransferredWTCount(store.displayedHistory())),
      availableAdvocatesPerStates: computed(() =>
        getSortedAvailableAdvocatesPerStates(SUPPORTED_STATES, store.wtAdvocatesInAvailableStatus()),
      ),
      availableAdvocateForInternalWt: computed(() => {
        const internalWtAdvocates = getAdvocatesWithInternalWtSkill(store.workingWtAdvocates());
        return getSortedAvailableAdvocatesPerStates(SUPPORTED_STATES, internalWtAdvocates);
      }),
    };
  }),
  withMethods((store, titleService = inject(Title), notificationService = inject(NotificationService)) => {
    return {
      setIncoming: (incoming: WarmTransferEvent[]) => patchState(store, { incoming }),
      setMuteState: (muted: boolean) => patchState(store, { muted }),
      setActiveState: (active: boolean) => patchState(store, { active, muted: false }),
      toggleHistoryPanel: () => patchState(store, { displayHistoryPanel: !store.displayHistoryPanel() }),
      flashPageTitleOnIncomingTransfers: rxMethod<WarmTransferEvent[]>(
        pipe(
          switchMap(() => {
            const defaultTitle = 'Protec8';
            const incomings = store.incomingWarmTransferAvailableForAdvocate();
            if (incomings.length === 0 || !store.active()) {
              titleService.setTitle(defaultTitle);
              return EMPTY;
            }
            const pageTitle = `(${incomings.length}) WT incoming | Protec8`;
            let title: string = pageTitle;
            titleService.setTitle(`(${incomings.length}) WT incoming | Protec8`);
            return interval(ONE_SECOND_AND_HALF).pipe(
              tap(() => {
                title = title === defaultTitle ? pageTitle : defaultTitle;
                titleService.setTitle(title);
              }),
            );
          }),
        ),
      ),
      notifyUserForIncomingTransfers: rxMethod<boolean>(
        pipe(
          switchMap((active) => {
            if (!active) {
              return EMPTY;
            }
            return interval(ONE_SECOND_AND_HALF);
          }),
          map(() => {
            return store
              .incomingWarmTransferAvailableForAdvocate()
              .filter(
                (wte) =>
                  isIncomingOlderThan(wte) &&
                  !store.notifiedWte().some((nw) => nw.apiReferenceUuid === wte.apiReferenceUuid),
              );
          }),
          filter((newIncomingsOlderThanThritySeconds) => newIncomingsOlderThanThritySeconds.length > 0),
          tap((newIncomingsOlderThanThritySeconds) => {
            patchState(store, { notifiedWte: [...newIncomingsOlderThanThritySeconds, ...store.notifiedWte()] });
          }),
          switchMap((newIncomingsOlderThanThritySeconds) => {
            if (!store.active() || store.muted() || newIncomingsOlderThanThritySeconds.length === 0) {
              return EMPTY;
            }

            const initialText =
              newIncomingsOlderThanThritySeconds.length === 1
                ? 'Incoming WT'
                : `${newIncomingsOlderThanThritySeconds.length} WTs`;

            return notificationService
              .displayNotification('Urgent Incoming WTE. Pick it up if you can!', {
                body: `${initialText} have been on hold for more than 30 seconds! Make yourself available if you can to pick them up.`,
                silent: !store.currentUserWorking(),
                tag: `${newIncomingsOlderThanThritySeconds.map((wte) => wte.apiReferenceUuid).join('-')}`,
              })
              .pipe(
                catchError((e) => {
                  console.error('An error occured while trying to display the notification', e);
                  return EMPTY;
                }),
              );
          }),
        ),
      ),
      cleanUpExpiredWarmTransferEvents: rxMethod<boolean>(
        pipe(
          switchMap((active) => {
            if (!active) {
              return EMPTY;
            }
            return interval(FIVE_SECONDS);
          }),
          tapResponse({
            next: () => {
              const incoming = getNonExpiredIncomingTransfers(store.incoming());
              patchState(store, { incoming });
            },
            error: (e) => console.error(e),
          }),
        ),
      ),
    };
  }),
  withMethods(
    (
      store,
      warmTransferService = inject(WarmTransferService),
      userStore = inject(UserStore),
      snackMessageService = inject(SnackMessageService),
    ) => {
      return {
        loadWtHistory: rxMethod<void>(
          pipe(
            switchMap(() => {
              return warmTransferService.getWarmTransferHistoryToday();
            }),
            tapResponse({
              next: (history) => {
                patchState(store, { history, loadingHistory: false });
              },
              error: (e) => {
                console.error(`Could not load history of WTE`, e);
                snackMessageService.showError(`An error occured while loading the WT history for today`);
                patchState(store, { loadingHistory: false });
              },
            }),
          ),
        ),
        getWtePickedUpEvents: rxMethod<boolean>(
          pipe(
            switchMap((active) => {
              if (!active) {
                return EMPTY;
              }
              return warmTransferService.getLiveContactEvent();
            }),
            filter((events) => {
              return events.some((event) => {
                return store
                  .incoming()
                  .some(
                    (wte) =>
                      isCallMatchingWte(wte, event) &&
                      event.status === LiveContactEventStatus.IN_PROGRESS &&
                      !!event.user_name,
                  );
              });
            }),
            tap((events) => {
              const incoming = store.incoming().filter((wte) => {
                const isWteInLiveCall = events.some((event) => isCallMatchingWte(wte, event));
                return !isWteInLiveCall;
              });
              patchState(store, { incoming });
            }),
          ),
        ),
        getWTEvents: rxMethod<boolean>(
          pipe(
            switchMap((active) => {
              if (!active) {
                return EMPTY;
              }
              return warmTransferService.getWarmTransferEvent();
            }),
            filter((wte) => wte.callTransferStatus.toLowerCase() !== CALL_TRANSFER_STATUS.PENDING.toLowerCase()),
            tap((event) => {
              const { incoming, history } = getUpdatedIncomingWtsAndHistoryAfterWtEvent(
                event,
                userStore.wtLicensedStates(),
                userStore.wtSkills(),
                {
                  incoming: store.incoming(),
                  history: store.history(),
                },
              );
              patchState(store, { incoming, history });
            }),
          ),
        ),
      };
    },
  ),
);

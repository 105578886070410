import { fp } from '@origin8-web/o8-utils/fp';
import {
  AVAILABLE_ADVOCATES_HIGH_CHANCE_THRESHOLD,
  WarmTransferState,
  WteWithAvailableAdvocates,
} from '../../../app.interface';
import {
  AVAILABLE_FOR_CALL_STATUSES,
  CALL_STATUS,
  CALL_TRANSFER_STATUS,
  LEGACY_WT_SKILL,
  LiveContactEvent,
  RepresentativeProfile,
  RepresentativeStatus,
  SUPPORTED_STATE,
  UserWTSkill,
  WT_SKILL,
  WarmTransferAdvocateState,
  WarmTransferEvent,
  WarmTransferRecord,
} from 'common.interfaces';
import { DateTime, Duration } from 'luxon';

const ONE_MINUTE = 60000; /* In ms */
const THIRTY_SECONDS = 30000; /* In ms */

export const isWteEventIncomingInLicensedStatesAndSkill = (
  event: Pick<WarmTransferEvent, 'stateFromAd' | 'warmTransferSkill'>,
  userWtSkills: UserWTSkill[],
  wtLicensedStates: SUPPORTED_STATE[],
): boolean => {
  return (
    wtLicensedStates.some((s) => s === event.stateFromAd) &&
    userWtSkills.some(
      (s) => event.warmTransferSkill.includes(s.name) || userWtSkills.some((s) => s.name === LEGACY_WT_SKILL),
    )
  );
};

export const isIncomingOlderThan = (incoming: WarmTransferEvent, timeLimit: number = THIRTY_SECONDS): boolean => {
  return DateTime.fromJSDate(incoming.createdOn) < DateTime.now().minus(Duration.fromMillis(timeLimit));
};

export const getUpdatedIncomingWtsAndHistoryAfterWtEvent = (
  event: WarmTransferEvent,
  wtLicensedStates: SUPPORTED_STATE[],
  userWtSkills: UserWTSkill[],
  { incoming, history }: Pick<WarmTransferState, 'incoming' | 'history'>,
): Pick<WarmTransferState, 'incoming' | 'history'> => {
  if (
    event.callTransferStatus === CALL_TRANSFER_STATUS.INCOMING &&
    isWteEventIncomingInLicensedStatesAndSkill(event, userWtSkills, wtLicensedStates)
  ) {
    if (!incoming.some((i) => i.apiReferenceUuid === event.apiReferenceUuid)) {
      const newIncoming = [event, ...incoming];
      return {
        history,
        incoming: newIncoming,
      };
    }
    return { history, incoming };
  }
  const newIncoming = incoming.filter((i) => i.apiReferenceUuid !== event.apiReferenceUuid);
  const record = fp.omit(event, 'availableAgentsForState') as WarmTransferRecord;
  const newHistory = history.some((r) => r.apiReferenceUuid === record.apiReferenceUuid)
    ? history
    : [record, ...history];
  return {
    history: newHistory,
    incoming: newIncoming,
  };
};

export const getNonExpiredIncomingTransfers = <T extends { createdOn: Date }>(
  transfers: T[],
  expireTimeInMs = ONE_MINUTE,
): T[] => {
  // filter out records older than 1min
  return transfers.filter(
    (r) => DateTime.fromJSDate(r.createdOn) > DateTime.now().minus(Duration.fromMillis(expireTimeInMs)),
  );
};

export const getIncomingWarmTransferInLicensedStatesAndSkill = (
  wtLicensedStates: SUPPORTED_STATE[],
  userWtSkills: UserWTSkill[],
  incoming: WarmTransferEvent[],
  advocatesInAvailableStatus: RepresentativeProfile[],
): WteWithAvailableAdvocates[] => {
  return incoming
    .filter((i) => isWteEventIncomingInLicensedStatesAndSkill(i, userWtSkills, wtLicensedStates))
    .map((i) => {
      return {
        ...i,
        availableAdvocatesCount: advocatesInAvailableStatus.filter((a) =>
          isWteEventIncomingInLicensedStatesAndSkill(i, a.wtSkills ?? [], a.wtLicensedStates ?? []),
        ).length,
      };
    });
};

export const getIncomingLicensedWarmTransferGroupedByState = (
  wteList: WarmTransferEvent[],
): Array<WarmTransferEvent & { occurences: number }> => {
  const res = wteList.reduce(
    (acc, wte) => {
      const wteWithSameState = acc.find((w) => w.stateFromAd === wte.stateFromAd);
      if (!wteWithSameState) {
        return [...acc, { ...wte, occurences: 1 }];
      }
      wteWithSameState.occurences = wteWithSameState.occurences + 1;
      return acc;
    },
    [] as Array<WarmTransferEvent & { occurences: number }>,
  );
  return res;
};

export const getAdvocateActiveState = (incoming: WarmTransferEvent[]): WarmTransferAdvocateState => {
  const hasIncomingInLicensedStates = incoming.length > 0;
  const isLikelyToReceiveCall = incoming.some(
    (i) => i.availableAgentsForState <= AVAILABLE_ADVOCATES_HIGH_CHANCE_THRESHOLD,
  ); /* For at least */
  return hasIncomingInLicensedStates && isLikelyToReceiveCall
    ? WarmTransferAdvocateState.READY
    : WarmTransferAdvocateState.RELAX;
};

export const getActiveStateImagePath = (activeState: WarmTransferAdvocateState): string => {
  return activeState === WarmTransferAdvocateState.RELAX
    ? '/assets/warm-leads/relax.svg'
    : '/assets/warm-leads/ready-to-fight.svg';
};

export const getAvailableAdvocatesPerStates = (
  wtLicensedStates: SUPPORTED_STATE[],
  availableAdvocates: RepresentativeProfile[],
): Array<{ state: SUPPORTED_STATE; availableAdvocates: RepresentativeProfile[] }> => {
  return wtLicensedStates.map((state) => {
    return {
      state,
      availableAdvocates: availableAdvocates.filter((a) => a.wtLicensedStates?.some((s) => s === state)),
    };
  });
};

export const getSortedAvailableAdvocatesPerStates = (
  wtLicensedStates: SUPPORTED_STATE[],
  availableAdvocates: RepresentativeProfile[],
): Array<{ state: SUPPORTED_STATE; availableAdvocates: RepresentativeProfile[] }> => {
  /* First sort by availableAdvocates number then alphabetically*/
  return getAvailableAdvocatesPerStates(wtLicensedStates, availableAdvocates).sort((a, b) => {
    if (a.state > b.state) {
      return 1;
    }
    if (b.state > a.state) {
      return -1;
    }
    return 0;
  });
};

/* Last record at the top*/
export const getSortedHistory = (history: WarmTransferRecord[]): WarmTransferRecord[] => {
  return [...history].sort((a, b) => {
    const aCreatedOn = DateTime.fromJSDate(a.createdOn);
    const bCreatedOn = DateTime.fromJSDate(b.createdOn);
    if (aCreatedOn > bCreatedOn) {
      return -1;
    }
    if (bCreatedOn > aCreatedOn) {
      return 1;
    }
    return 0;
  });
};

export const getTransferredWTCount = (wts: WarmTransferRecord[]): number => {
  return wts.filter((wt) => wt.callTransferStatus === CALL_TRANSFER_STATUS.COMPLETED).length;
};

export const getMinAvailableAgentsForIncomingWts = (incomings: WarmTransferEvent[]): number => {
  const baseline = 100000; /* Arbitrary baseline so all incoming wts have availableAgentsForState lower than that */
  return incomings.reduce((min, wte) => {
    return wte.availableAgentsForState < min ? wte.availableAgentsForState : min;
  }, baseline);
};

export const getOnlineWtAdvocates = (
  representatives: RepresentativeProfile[],
  statuses: RepresentativeStatus[],
): RepresentativeProfile[] => {
  return representatives.filter((r) => {
    const status = statuses.find((s) => s.user_id === r.talkdeskUserId)?.status_name ?? CALL_STATUS.OFFLINE;
    const isInCallableStatus = AVAILABLE_FOR_CALL_STATUSES.includes(status as any);
    const hasWtStates = r.wtLicensedStates?.length ?? 0 > 0;
    return isInCallableStatus && hasWtStates;
  });
};

export const getAvailableWtAdvocates = (
  representatives: RepresentativeProfile[],
  statuses: RepresentativeStatus[],
): RepresentativeProfile[] => {
  return representatives.filter((r) => {
    const status = statuses.find((s) => s.user_id === r.talkdeskUserId)?.status_name ?? CALL_STATUS.OFFLINE;
    const isInCallableStatus = status === CALL_STATUS.READY;
    const hasWtStates = r.wtLicensedStates?.length ?? 0 > 0;
    return isInCallableStatus && hasWtStates;
  });
};

export const getAdvocatesWithInternalWtSkill = (advocates: RepresentativeProfile[]): RepresentativeProfile[] => {
  return advocates.filter((a) => a.wtSkills?.some((s) => s.name === WT_SKILL.INTERNAL || s.name === LEGACY_WT_SKILL));
};

export const isCallMatchingWte = (wte: WarmTransferEvent, liveContactEvent: LiveContactEvent): boolean => {
  const usIndicator = '+1';
  return (
    `${usIndicator}${wte.ani}` === `${liveContactEvent.contact_number}` ||
    `${usIndicator}${wte.ani}` === `${liveContactEvent.contact_info}`
  );
};
